import clsx from "clsx";
import React from "react";
import { AlertCircle } from "react-feather";

type InlineErrorProps = {
  children?: string;
  fieldID?: string;
  className?: string;
};

export const InlineError = ({ children, fieldID, className }: InlineErrorProps) => {
  if (children === undefined) return null;
  return (
    <div
      id={`${fieldID}Error`}
      className={clsx("flex flex-1 items-center gap-1.5 text-sm text-red-700", className)}>
      <div className={"flex-shrink-0"}>
        <AlertCircle size="14" />
      </div>
      {children}
    </div>
  );
};
