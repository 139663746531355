import type { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { Button } from "src/components/rough/Button";
import { Fieldset } from "src/components/rough/Fieldset";
import { Form } from "src/components/rough/Form";
import { Label } from "src/components/rough/Label";
import { Logo } from "src/components/rough/Logo";
import { PasswordInput, TextInput } from "src/components/rough/TextInput";
import { getErrorMessage, RequiredString } from "src/utils/validate";
import { Credentials, login } from "../data/auth/login";

import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronRight } from "react-feather";
import { LimbicLink } from "src/components/primitives/Link";
import { InlineError } from "src/components/rough/InlineError";
import { Text } from "src/components/rough/Text";
import config from "src/config";
import { getMeFromCookie } from "src/data/auth/getMe";
import { LimbicUser } from "src/types/user";
import { setAuthToken, setPermissions, setRefreshToken } from "src/backend/helpers/authToken";

const schema = yup.object().shape({
  email: RequiredString("Please enter a valid email address."),
  password: RequiredString("Please enter a valid password."),
});

const LoginPage: NextPage<{ user: LimbicUser }> = ({ user }) => {
  const {
    handleSubmit,
    getValues,
    formState: { errors },
    control,
  } = useForm<Credentials>({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const router = useRouter();

  const onLogin: SubmitHandler<Credentials> = async () => {
    try {
      setIsLoading(true);
      const values = getValues();
      const { accessToken, refreshToken, permissions } = await login(values);
      setAuthToken(accessToken);
      setRefreshToken(refreshToken);
      setPermissions(permissions);
      router.push("/courses");
      setIsInvalid(false);
      setErrorMessage("");
    } catch (e) {
      setIsInvalid(true);
      setErrorMessage(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Head>
        <title>Limbic Courses _ login</title>
        <meta name="description" content="Limbic Courses" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex-shrink-1 relative flex h-full w-full flex-1 flex-col overflow-auto bg-white">
        <div className={"flex h-full min-h-screen items-center justify-center"}>
          <div className={"flex w-80 flex-col items-center"}>
            <div className={"flex flex-col items-center gap-6"}>
              <Logo width={156} height={36} />
              <h2 className="text-2xl font-semibold">Welcome back!</h2>
              {!!user && (
                <Text className={"text-center text-sm"}>
                  You&apos;re already logged in as <strong>{user.name}</strong>. <br />
                  <LimbicLink href="/courses" className={"group flex w-full justify-center"}>
                    <span className="flex items-center gap-0.5 transition-all duration-standard ease-in-out group-hover:tracking-wide">
                      Proceed to courses{" "}
                      <ChevronRight
                        size={14}
                        className={
                          "transition-transform duration-standard ease-in-out group-hover:translate-x-2"
                        }
                      />
                    </span>
                  </LimbicLink>
                </Text>
              )}
            </div>
            <div className={"mt-8 w-full"}>
              <Form gap={0} onSubmit={handleSubmit(onLogin)}>
                <Fieldset>
                  <Label htmlFor="email">Email</Label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onBlur, onChange, ...fieldProps } }) => (
                      <TextInput
                        id="email"
                        value={value}
                        onBlur={onBlur}
                        onValueChange={(value) => onChange(value)}
                        autoComplete="username"
                        isInvalid={errors.email?.message !== undefined}
                        {...fieldProps}
                      />
                    )}
                  />

                  <InlineError className="-mt-2" fieldID="email">
                    {errors.email?.message}
                  </InlineError>
                </Fieldset>
                <Fieldset className="mt-2">
                  <Label htmlFor="password">Password</Label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onBlur, onChange, ...fieldProps } }) => (
                      <PasswordInput
                        id="password"
                        value={value}
                        onBlur={onBlur}
                        onValueChange={(value) => onChange(value)}
                        autoComplete="current-password"
                        isInvalid={errors.password?.message !== undefined}
                        {...fieldProps}
                      />
                    )}
                  />

                  <InlineError className="-mt-2" fieldID="password">
                    {errors.password?.message}
                  </InlineError>
                </Fieldset>

                <LimbicLink href={"/forgot"}>Forgot your password?</LimbicLink>

                <div className={"mt-6"}>
                  {isInvalid ? <InlineError>{errorMessage}</InlineError> : null}
                  <Button
                    type="submit"
                    text="Log in"
                    variant="primary"
                    fullWidth
                    isLoading={isLoading}
                  />
                  {config.IS_SIGNUP_ENABLED && (
                    <LimbicLink href="/signup">Click here to sign up</LimbicLink>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const user = getMeFromCookie(context.req.cookies);

  return {
    props: {
      user: user,
    },
  };
};

export default LoginPage;
