import clsx from "clsx";
import NextLink, { LinkProps } from "next/link";
import React from "react";

export const LimbicLink: React.FC<LinkProps & { className?: string }> = ({
  href,
  className,
  ...rest
}) => (
  <NextLink href={href} passHref legacyBehavior>
    <a
      className={clsx(
        "w-full",
        "leading-0 text-right text-sm",
        "text-gray-500 hover:text-gray-800",
        "transition-all duration-standard ease-in-out",
        className,
      )}
      {...rest}
    />
  </NextLink>
);
