import config from "../../config";
import { NetworkError } from "../../utils/errors";

const method = "POST";
const headers = new Headers();
headers.set("Content-Type", "application/json");

export interface Credentials {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  permissions?: string[];
}

export async function login(payload: Credentials): Promise<LoginResponse> {
  const body = JSON.stringify(payload);
  const result = await fetch(`${config.loginEndpoint}`, {
    body,
    method,
    headers,
  });
  const data = await result.json();

  if (data?.error) {
    throw new NetworkError(data?.code, data?.error);
  }
  if (data?.message) {
    throw new NetworkError(data?.code, data?.message);
  }
  const response = data.data as LoginResponse;
  const permissions = data.permissions as string[] | undefined;
  return {
    ...response,
    permissions,
  };
}
